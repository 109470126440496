const docsHost = "https://docs.olares.xyz";
const marketHost = "https://market.olares.xyz";

export default {
  docs_path: `${docsHost}/manual/docs-home.html`,
  market_path: marketHost,
  // page1
  get_started: `${docsHost}/manual/get-started/`,

  //   page2 //这一页省略了
  bec_introduction: `${docsHost}/manual/concepts/architecture.html`,
  snowinning_protocol: `https://docs.snowinning.com/protocol/overview.html`, //站外
  terminus_OS: `${docsHost}/manual/feature-overview.html`,
  termiPass: `https://www.olares.xyz/larepass`, //指向 larepass
  snowinning_protocol_read_more: `https://docs.snowinning.com/protocol/overview.html`, //站外，和 snowinning_protocol 指向地址相同

  // page4
  desktop_learn_more: ``,
  files_learn_more: `${docsHost}/manual/tasks/files.html`,
  vault_learn_more: `${docsHost}/manual/tasks/vault.html`,
  market_learn_more: `${docsHost}/manual/tasks/install-uninstall-update.html`,
  wise_learn_more: `${docsHost}/manual/tasks/wise.html`,
  dashboard_learn_more: `${docsHost}/manual/tasks/resources-usage.html`,
  profile_learn_more: `${docsHost}/manual/tasks/profile.html`,
  devbox_leanr_more: `${docsHost}/developer/develop/tutorial/devbox.html`,
  controlHub_leanr_more: `${docsHost}/manual/tasks/navigate-control-hub.html`,

  //   page6
  network_learn_more: `${docsHost}/manual/concepts/network.html`,
  account_learn_more: `${docsHost}/manual/concepts/account.html`,
  application_sandbox_learn_more: `${docsHost}/manual/concepts/application.html`,
  secret_management_learn_more: `${docsHost}/manual/concepts/secrets.html`,

  // page7
  market_learn_more_2: `https://market.olares.xyz/`,
  distribute_learn_more: `https://github.com/beclab/apps`,
  develop_learn_more: `${docsHost}/developer/develop/tutorial/devbox.html`,

  //   page11
  AI_assistant_learn_more: ``,

  //   page12
  movies_learn_more: `${marketHost}/app/jellyfin`,
  music_learn_more: `${marketHost}/app/navidrome`,

  //   page13
  Home_Assitant: `${marketHost}/app/homeassistant`,

  //   page14
  ghost: `${marketHost}/app/ghost`,
  mastodon: `${marketHost}/app/mastodon`,

  //   page15
  distribution_protocol_learn_more: ``, //不存在

  //   page16
  otmoic_protocol: `${docsHost}/overview/protocol/otmoic.html`, //不存在

  // page17. Special thanks

  kubesphere: "https://kubesphere.io/",
  kubernetes: "https://kubernetes.io/",
  juicefs: "https://juicefs.com/en/",
  headscale: "https://headscale.net/",
  tailscale: "https://tailscale.com/",
  dify: "https://dify.ai/",
  seafile: "https://www.seafile.com/en/home/",
  redis_operator: "https://github.com/spotahome/redis-operator",
  jan: "https://jan.ai/",
  nitro: "https://nitro.jan.ai/",
  rss: "https://rss.com/",
  predixy: "https://github.com/joyieldInc/predixy",
  mongodb: "https://docs.percona.com/percona-operator-for-mongodb/index.html",
  nvshare: "https://github.com/grgalex/nvshare",
  infisical: "https://infisical.com/",
  langchain: "https://www.langchain.com/",
  quasar: "https://quasar.dev/",
  padloc: "https://padloc.app/",
  trustwallet: "https://trustwallet.com/",
  envoyproxy: "https://www.envoyproxy.io/",
  restic: "https://restic.net/",
  zincsearch: "https://zincsearch-docs.zinc.dev/",
  k3s: "https://k3s.io/",
  authelia: "https://www.authelia.com/",
  filebrowser: "https://filebrowser.org/",
  lego: "https://go-acme.github.io/lego/",
  velero: "https://velero.io/",
  s3rver: "https://github.com/jamhall/s3rver",
  citusdata: "https://www.citusdata.com/",


  //   page18
  footer_get_started: `${docsHost}/manual/get-started/`,
  footer_github: "https://github.com/beclab",
  footer_discord: "https://discord.com/invite/BzfqrgQPDK",
  footer_twitter: "https://twitter.com/TerminusOS", //未来需要变化
  footer_medium: "https://jointerminus.medium.com", //未来需要变化
  footer_terminus_os: "https://github.com/beclab/terminus", //未来需要变化
  footer_termi_pass: "https://github.com/beclab/TermiPass", //未来需要变化
  footer_terminus_space: "https://space.olares.xyz/", 
  footer_snowinning: `https://docs.snowinning.com/protocol/overview.html`, //指向站外
  footer_docs: `${docsHost}/manual/docs-home.html`,
  buy: 'https://item.taobao.com/item.htm?ft=t&id=834871818705&spm=a21dvs.23580594.0.0.4d562c1bUwAzjX&skuId=5757075243520',
  zero_learn_more: 'https://w8wvyn24o5.feishu.cn/wiki/A2V9wbWsuiTOKek2DAgcdFONnvh',

  // TermiPass
  download_android: 'https://pub-5d6cd233ee6f494c9f36edb0ccc00981.r2.dev/TermiPass/v1.2.47/TermiPass_64_v1.2.47_2024-10-10_11-10.apk',
  download_ios: 'https://pub-5d6cd233ee6f494c9f36edb0ccc00981.r2.dev/TermiPass/v1.2.47/TermiPass.ipa',
  download_mac: 'https://pub-5d6cd233ee6f494c9f36edb0ccc00981.r2.dev/TermiPass/v1.2.47/TermiPass.dmg',
  download_windows: 'https://pub-5d6cd233ee6f494c9f36edb0ccc00981.r2.dev/TermiPass/v1.2.47/TermiPass.exe',
  download_chrome: 'https://pub-5d6cd233ee6f494c9f36edb0ccc00981.r2.dev/TermiPass/v1.2.47/TermiPass-chrome.zip',
  download_ios_mobile: 'https://apps.apple.com/us/app/termipass/id6448082605',
};
